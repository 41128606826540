import React from 'react'

import SRModal from "../modal/modal"

const TermsOfSales = ({ text }) => (
    <SRModal title={"General Terms and Conditions of Sale"} text={text} className={"addMember"}>
        <div className={"content content-modal"}>
            <ul>
                <li>
                    Fees applicable according  to the  payment date
                </li>
                <li>
                    Due to the nature of a virtual conference, with all content available to view on demand, our policy is that we cannot accept cancellations or provide refunds.
                </li>
            </ul>
        </div>
    </SRModal>
)

export default TermsOfSales
