import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import PropTypes from "prop-types"

import "./modal.scss"

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        padding: '0px',
        transform: 'translate(-50%, -50%)',
        boxShadow: '0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22)',
        border: 'none',
    }
};

Modal.setAppElement(`#___gatsby`)

const SRModal = ({ text, title, subtitle, className, classbtnName, children, onClose, onOpen, isOpen }) => {
    const [modalIsOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (isOpen === false)
            closeModal()
    }, [isOpen])

    const openModal = () => {
        setIsOpen(true)
        onOpen()
    }
    const afterOpenModal = () => { }
    const closeModal = () => {
        setIsOpen(false)
        onClose()
    }

    return (
        <>
            <div className={classbtnName} onClick={openModal} title={title}>
                {text}
            </div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel={title}
            >
                <div className={"sr-modal " + className}>
                    <div className={"sr-modal-header"}>
                        <h2 ref={_subtitle => (subtitle = _subtitle)}>{title}</h2>
                        <button className={"close-modal"} onClick={closeModal} title={"Close"}>×</button>
                    </div>

                    {children}
                </div>
            </Modal>
        </>
    )
}

SRModal.propTypes = {
    children: PropTypes.node.isRequired,
    text: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    className: PropTypes.string,
    classbtnName: PropTypes.string,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    isOpen: PropTypes.bool,
}

SRModal.defaultProps = {
    text: "",
    title: "",
    subtitle: "",
    className: "",
    classbtnName: "termsOfSales",
    onClose: () => { },
    onOpen: () => { },
}

export default SRModal
